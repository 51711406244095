// Home.js
import React from 'react';
import PhotoGallery from './PhotoGallery';
import { AppBar, Toolbar, Typography, Container, Button } from '@mui/material';

const Home = ({ setLoggedIn }) => {
  console.log("Home component is rendering"); // Console log for debugging

  const handleLogout = () => {
    // Clear local storage or perform any other logout logic
    localStorage.clear();
    setLoggedIn(false);
  };

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Moments App
          </Typography>
          <Button color="inherit" onClick={handleLogout}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <Container>
        <PhotoGallery setLoggedIn={setLoggedIn} />
      </Container>
    </div>
  );
};

export default Home;
