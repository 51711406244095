import React, { useState, useEffect } from 'react';
import { fetchPhotos, refreshToken } from './api';
import { Grid, Paper, Box, Typography, Divider} from '@mui/material';

const PhotoGallery = ({ setLoggedIn }) => {
  const [groupedPhotos, setGroupedPhotos] = useState([]);

  const loadPhotos = async () => {
    try {
      const response = await fetchPhotos();
      if (response.data.photos && response.data.photos.length > 0) {
        const grouped = groupPhotosByDay(response.data.photos);
        setGroupedPhotos(grouped);
      } else {
        console.log("No photos returned from the API");
      }
    } catch (error) {
      console.error("Error fetching photos:", error);
    }
  };


  const groupPhotosByDay = (photos) => {
    const grouped = {};
    photos.forEach((photo) => {
      const createdAt = new Date(photo.created_at);
      const today = new Date();
      const isToday =
        createdAt.getDate() === today.getDate() &&
        createdAt.getMonth() === today.getMonth() &&
        createdAt.getFullYear() === today.getFullYear();
  
      const dateKey = isToday ? 'today' : createdAt.toDateString();
  
      if (!grouped[dateKey]) {
        grouped[dateKey] = [];
      }
  
      grouped[dateKey].push(photo);
    });
  
    // Sort photos within each date group by the created_at timestamp in descending order
    Object.keys(grouped).forEach((date) => {
      grouped[date] = grouped[date].sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
    });
  
    // Sort date groups based on whether the date is today
    const sortedDates = Object.keys(grouped).sort((a, b) => {
      if (a === 'today') return -1;
      if (b === 'today') return 1;
      return new Date(b) - new Date(a);
    });
  
    // Return sorted date groups
    return sortedDates.reduce((sortedGrouped, date) => {
      sortedGrouped[date] = grouped[date];
      return sortedGrouped;
    }, {});
  };
  

  useEffect(() => {
    loadPhotos();
  }, []);

  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      {Object.entries(groupedPhotos).map(([date, photos]) => (
        <Box key={date} mb={3}>
          <Typography variant="h6">{date === 'today' ? 'Today' : date}</Typography>
          <Grid container spacing={2}>
            {photos.map((photo) => (
              <Grid item xs={12} sm={6} md={4} key={photo.id}>
                <Paper elevation={0}>
                  <img
                    src={photo.image}
                    alt="Photo"
                    style={{ width: '100%', height: 'auto', border: date === 'today' ? '2px solid red' : 'none' }}
                  />
                  
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>
      ))}
    </Box>
  );
};

export default PhotoGallery;

