// App.js
import React, { useState, useEffect } from 'react';
import Login from './components/Login';
import Home from './components/Home';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Registration from './components/Registration'; // Import the Registration component


const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    setLoggedIn(!!accessToken);
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={loggedIn ? <Navigate to="/home" /> : <Login setLoggedIn={setLoggedIn} />} />
        <Route path="/home" element={loggedIn ? <Home setLoggedIn={setLoggedIn} /> : <Navigate to="/" />} />
        <Route path="/register" element={<Registration />} /> {/* Add registration route */}
      </Routes>
    </Router>
  );
};

export default App;
