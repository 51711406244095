import axios from 'axios';

const API = axios.create({ baseURL: process.env.REACT_APP_API_HOST+'/api/' });
console.log(process.env.REACT_APP_API_HOST)

export const fetchPhotos = () => API.get('/photos/');
export const loginUser = (email, password) => API.post('/token/', { email, password });
export const refreshToken = (token) => API.post('/token/refresh/', { refresh: token });
export const registerUser = (email, password) => API.post('/register/', { email, password });

// Request interceptor to add the access token to every request
API.interceptors.request.use((req) => {
  if (localStorage.getItem('accessToken')) {
    req.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
  }
  return req;
});

// Function to handle token renewal
const handleTokenRenewal = async (error) => {
  const originalRequest = error.config;
  if (error.response.status === 401) {
    originalRequest._retry = true;
    try {
      const refreshTokenValue = localStorage.getItem('refreshToken');
      const { data } = await refreshToken(refreshTokenValue);
      localStorage.setItem('accessToken', data.access);
      localStorage.setItem('refreshToken', data.refresh);
      originalRequest.headers['Authorization'] = `Bearer ${data.access}`;
      return API(originalRequest);
    } catch (refreshError) {
      console.error("Token refresh failed:", refreshError);
      return Promise.reject(refreshError);
    }
  }
  return Promise.reject(error);
};

// Response interceptor for handling token expiration
API.interceptors.response.use(
  (response) => response,
  handleTokenRenewal
);


export default API;
